import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ab2ab642"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list" }
const _hoisted_2 = { class: "list-item" }
const _hoisted_3 = { class: "exam-title" }
const _hoisted_4 = { class: "exam-extra" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_list = _resolveComponent("van-list")!
  const _component_van_pull_refresh = _resolveComponent("van-pull-refresh")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_pull_refresh, {
      modelValue: _ctx.refreshing,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.refreshing) = $event)),
      onRefresh: _ctx.onRefresh
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_list, {
          style: {"height":"100%"},
          modelValue: _ctx.loading,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.loading) = $event)),
          finished: _ctx.finished,
          "finished-text": "没有更多了",
          onLoad: _ctx.onLoad
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "list-itembox",
                key: index
              }, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("span", null, _toDisplayString(index + 1) + ". ", 1),
                      _createElementVNode("span", null, _toDisplayString(item.name), 1)
                    ]),
                    _createElementVNode("div", _hoisted_4, "共" + _toDisplayString(item.total) + "题", 1)
                  ]),
                  _createElementVNode("div", {
                    class: "btn",
                    onClick: ($event: any) => (
                _ctx.$router.push({
                  path: '/app/examquestions',
                  query: { id: item.id, name: item.name },
                })
              )
                  }, " 去测试 ", 8, _hoisted_5)
                ])
              ]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue", "finished", "onLoad"])
      ]),
      _: 1
    }, 8, ["modelValue", "onRefresh"])
  ]))
}