
import { Options, Vue } from "vue-property-decorator";
import ExamHistoryList from "./examHistoryList.vue";
import ExamList from "./examList.vue";
@Options({
  name: "Onlineexam",
  components: {
    ExamHistoryList,
    ExamList,
  },
})
export default class extends Vue {
  activeTab = 0;
}
