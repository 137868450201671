
import { Vue } from "vue-property-decorator";
import api from "@/api/exam";
export default class extends Vue {
  list: any = [];
  loading = false;
  finished = false;
  refreshing = false;
  page = {
    pageNum: 1,
    pageSize: 10,
    total: 0,
  };

  onLoad() {
    setTimeout(() => {
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }
      // 请求数据
      api
        .examhistory({
          pageSize: this.page.pageSize,
          pageNum: this.page.pageNum,
          projectId: this.$store.state.project.projectinfo.projectId,
        })
        .then((res) => {
          this.list = [...this.list, ...res.data.records];
          // 设置下一页
          this.page.pageNum = res.data.current + 1;
          // 结束当前页的加载状态
          this.loading = false;
          // 设置加载完毕
          if (res.data.current >= res.data.pages) {
            this.finished = true;
          }
        });
    }, 500);
  }
  onRefresh() {
    // 刷新时设置当前页为第一页
    this.page.pageNum = 1;
    // 设置为未完全加载状态
    this.finished = false;
    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    this.loading = true;
    this.onLoad();
  }
}
